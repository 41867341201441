/* src/components/GoalCard.css */

/* Interaction Section Styling */
.interaction-section {
    margin-top: 1em;
    border-top: 1px solid #e0e0e0;
    padding-top: 1em;
  }
  
  .interaction-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  /* Goal Description Styling */
  .goal-description {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0.5em 0;
  }
  
  /* Responsive Adjustments */
  @media (min-width: 768px) {
    .goal-description {
      font-size: 1.1rem;
    }
  }
  