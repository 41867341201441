
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body.no-scrollbar {
  overflow: hidden;
}

.dropdown-transition {
    transition: opacity 0.3s ease;
    opacity: 1;
}

.dropdown-transition.hidden {
    opacity: 0;
}
.success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #83c5be;
}

.checkmark {
  color: white;
  animation: pop 0.5s ease-in-out;
  font-size: 10rem; /* Adjust this value to make the icon bigger */
}

@keyframes pop {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.feed-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.feed-wrapper {
  height: calc(100vh - 72px); /* Adjust based on your actual header's height */
  overflow-y: scroll;
  background-color: #479d9d;
  scrollbar-width: none;  /* For Firefox */
  ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.feed-wrapper::-webkit-scrollbar {
  display: none;  /* For Chrome, Safari, and Opera */
}

.feed-container-desktop {
  max-width: 800px; /* or whatever width you prefer */
  margin: 0 auto;  /* auto margins on left and right will center it */
}



.feed-summary {
  color: #f5f5f5 !important; /* Force white color */
}

.feed-date {
  color: #c2dfe3 !important; /* Force date color */
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.comment-form {
  margin-top: 1em;
}

.comment-text {
  color: #f5f5f5 !important; /* Force white color */
}

.feed-styles {
  padding: 1em;
  color: #f5f5f5 !important; /* Force white color */
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 72px);
  color: #f5f5f5;
  background-color: #479d9d;
}

.custom-menu-item .icon {
  color: white !important;
}

.custom-menu-item.active .icon {
  color: white !important; /* Slightly darker shade */
}

.custom-menu-item.active {
  background-color: rgba(5, 5, 5, 0.274) !important; /* Slightly darker background */
}

.ui.menu .item > i.icon {
  margin: 0 !important; /* Remove the right margin */
  opacity: .7; /* Retain the default opacity */
  float: none; /* Retain the default float */
}

.ui.menu .item:before {
  background:none !important;
}

.ui.secondary.menu .active.item {
  background: rgba(0,0,0,.3) !important;
}

.ui.secondary.menu {
  padding-top: 5px;
  margin: 0px !important;
}

.feedsegment {
  padding:0 !important;
}

.ui.secondary.menu .item {
  margin: 0 !important;
}

.right.menu {
  margin-right: .5em !important;
}

.feed-item .meta {
  display: flex;
  align-items: center;
  justify-content: left; /* Center the items */
  gap: 40px; /* Add gap between like and comment sections */
  padding-top: 10px; /* Add more padding at the top */
}

.feed-item .meta .like, .feed-item .meta .comment, .feed-item .meta .dollar {
  display: flex;
  align-items: center; /* Ensure vertical alignment */
  color: white !important;
  cursor: pointer; /* Add cursor on hover */
}

.feed-item .meta .icon {
  margin-right: 5px; /* Adjust spacing */
}

.feed-item .meta .like:hover, .feed-item .meta .comment:hover, .feed-item .meta .dollar:hover {
  text-decoration: underline;
}

.feed-item {
  padding: 1em;
  margin-bottom: 1em;
  background-color: #409e9e;
  border-radius: 5px;
  color: #f5f5f5 !important; /* Ensure text is white */
}

.last-feed-item {
  padding: 1em;
  margin-bottom: 10em !important;
  border-radius: 5px;
  color: #f5f5f5 !important; /* Ensure text is white */
}
.last-feed-item .meta {
  display: flex;
  align-items: center;
  justify-content: left; /* Center the items */
  gap: 40px; /* Add gap between like and comment sections */
  padding-top: 10px; /* Add more padding at the top */
}

.last-feed-item .meta .like, .last-feed-item .meta .comment, .last-feed-item .meta .dollar {
  display: flex;
  align-items: center; /* Ensure vertical alignment */
  color: white !important;
  cursor: pointer; /* Add cursor on hover */
}

.last-feed-item .meta .icon {
  margin-right: 5px; /* Adjust spacing */
}

.last-feed-item .meta .like:hover, .last-feed-item .meta .comment:hover, .last-feed-item .meta .dollar:hover {
  text-decoration: underline;
}

.comment-text-black {
  color: black !important;
}

.comment-text-white {
  color: white !important;
}

.icon-black {
  color: black !important;
}

.icon-white {
  color: white !important;
}
