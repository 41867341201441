/* HomePage.css */

/* Container to center content and set max-width */
.content-container {
  max-width: 1200px; /* Adjust as needed */
  margin: 0 auto;
  padding: 0 1rem; /* Optional: Adds horizontal padding */
}

/* Hero Section */
.hero-section {
  background: linear-gradient(-45deg, #006d77, #83c5be);
  padding: 4rem 1rem;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: white;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: white;
}

.get-started-button,
.login-button {
  margin-top: 1rem;
}

/* Features Section */
.features-section, .research-section {
  padding: 2rem 1rem;
}

.feature-card {
  margin-bottom: 2rem;
}

/* Supporting Research Section */
.section-divider {
  margin: 2rem 0;
  border-bottom: 2px solid #e0e0e0;
  display: inline-block;
  padding-bottom: 0.5rem;
}

.section-divider ion-text {
  background: #fff;
  padding: 0 1rem;
  font-weight: bold;
}

.research-card {
  margin-bottom: 2rem;
}

/* Footer */
.footer {
  background-color: #f8f8f8;
  padding: 2rem 1rem;
}

.footer-section h4 {
  margin-bottom: 1rem;
}

.subscribe-form {
  margin-top: 1rem;
}

.subscribe-form ion-input {
  width: 100%;
}

.subscribe-form ion-button {
  width: 100%;
  margin-top: 0.5rem;
}

/* Button Adjustments for Desktop */
@media (min-width: 768px) {
  .get-started-button,
  .login-button,
  .cta-button {
    max-width: 300px; /* Limits button width */
    margin: 1em auto; /* Centers the buttons */
  }

  .cta-button {
    margin-top: 1rem;
  }

  .subscribe-form ion-button {
    width: auto;
    margin-top: 0;
  }
}

ion-input.wait-input {
  color: white;
}
